import { above, below } from 'src/components/global/mediaqueries';
import AspectWrapper from 'src/components/AspectWrapper';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const ReferenceWrapper = styled('div')`
    display: flex;
    height: auto;
    &.left {
        flex-direction: row-reverse;
    }
    ${below.tablet_s} {
        display: block;
    }
`;

const ImageWrapper = styled('div')`
    height: 100%;
    width: 100%;

    ${above.tablet_s} {
        width: calc(4 * 100%/12);
    }
`;

const ContentWrapper = styled('div')`
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    ${above.tablet_s} {
        justify-content: center;
    }

    ${below.tablet_s} {
        min-height: 100vw;
        ${theme.spacing.mobile._16('padding')};
        ${theme.spacing.mobile._40('padding-bottom')};
    }

    ${above.tablet_s} {
        width: calc(8 * 100%/12);
    }

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.desktop._32('padding')}

    ${above.tablet_s} {
        padding-right: 0 !important;
    }
`;

const ContentTag = styled(Tag)`
    ${theme.spacing.mobile._24('margin-bottom')};

    ${above.tablet_s} {
        position: absolute;
        margin-bottom: 0;
    }

    ${theme.spacing.tablet._20('top')};
    ${theme.spacing.tablet._20('left')};
    ${theme.spacing.desktop._32('top')};
    ${theme.spacing.desktop._32('left')};
`;

const ContentHeading = styled('div')`
    line-height: 1.15em;

    ${theme.fontSize.sans.mobile._24};
    ${theme.fontSize.sans.tablet._24};
    ${theme.fontSize.sans.desktop._40};

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._20('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};

    ${above.tablet_s} {
        margin-right: calc(100vw / 12 * 2);
    }
    ${above.desktop_s} {
        margin-right: calc(100vw / 12 * 2);
    }
`;

const ContentParagraph = styled('div')`
    line-height: 1.6em;
    font-size: 16px;

    ${above.tablet_s} {
        margin-right: calc(100vw / 12 * 1);
    }
    ${above.desktop_s} {
        margin-right: calc(100vw / 12 * 2);
    }
`;

const ContentImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
    transform: scale(1.1);

    &.standard {
        filter: blur(${theme.blur.standard.desktop});
    }
    ${below.tablet_s} {
        &.standard {
            filter: blur(${theme.blur.standard.mobile});
        }
    }
`;

const ReferenceModule = ({data}) => {
    const {
        backgroundColor,
        heading,
        image,
        paragraph,
        tag,
        textAlignment,
    } = data;

    // const moduleBackground = theme.color[backgroundColor] || theme.color.white;
    // const moduleTextColor = theme.backgroundTextColor[backgroundColor] || theme.color.black;
    // const direction = textAlignment === 'right' ? 'right' : '';

    return null;

    // return (
    //     <>
    //         <ReferenceWrapper style={{backgroundColor: moduleBackground}} className={direction}>
    //             <ImageWrapper>
    //                 <AspectWrapper ratio={[1 / 1, null, 2 / 3]}>
    //                     <ContentImage fluid={image.desktop.image.medium}
    //                         style={{
    //                             height: '100%',
    //                             width: '100%',
    //                             position: 'absolute',
    //                             top: '0',
    //                             left: '0',
    //                         }}
    //                     />
    //                 </AspectWrapper>
    //             </ImageWrapper>

    //             <ContentWrapper style={{color: moduleTextColor}}>
    //                 <ContentTag>{tag}</ContentTag>
    //                 <ContentHeading>{heading}</ContentHeading>
    //                 <ContentParagraph>{paragraph}</ContentParagraph>
    //             </ContentWrapper>
    //         </ReferenceWrapper>
    //     </>
    // );
};

// ReferenceModule.propTypes = {
//     data: PropTypes.shape({
//         backgroundColor: PropTypes.string.isRequired,
//         heading: PropTypes.string.isRequired,
//         image: PropTypes.object,
//         paragraph: PropTypes.string,
//         tag: PropTypes.string,
//         textAlignment: PropTypes.string.isRequired,
//     })
// };

// ReferenceModule.defaultProps = {
//     data: {
//         image: {},
//         paragraph: '',
//         tag: '',
//     }
// };

export default ReferenceModule;

